
import {  PageBase } from '@/core/models/shared';
import { RelatorioService } from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';

@Component
export default class RelatorioDespesaChave extends PageBase { 
    service = new RelatorioService();
    item: Model[] = [];
    overlay: boolean = false;
    filter:any = {}

    mounted() {
       this.filter = this.$route.query;
        this.overlay = true;  

            this.service.ObterRelatorioDimob(this.filter.empresaId, this.filter.empreendimentoId).then(
                res => {
                    this.item = res.data;
                }, 
                err => {
                    if (!err.response) {
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } 
                    else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                } 
            ).finally(() =>{this.overlay = false});
        } 
}

class Model {
   public cpf: string = "";
   public nome: string = "";
   public numeroContrato: number = 0;
   public dataContrato: string = "";
   public tipoImovel: string = "";
   public valorTotalContrato: number = 0;
   public valorPagoNoAno: string = "";
   public dataPagamento: string = "";
   public logradouro: string = "";
   public numero: string = "";
   public uf: string = "";
   public municipio: string = "";
   public cep: string = "";
}
